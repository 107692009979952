/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./NoTokensReviewManagement.vue?vue&type=template&id=ab4fe708&scoped=true&"
import script from "./NoTokensReviewManagement.vue?vue&type=script&lang=js&"
export * from "./NoTokensReviewManagement.vue?vue&type=script&lang=js&"
import style0 from "./NoTokensReviewManagement.vue?vue&type=style&index=0&id=ab4fe708&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab4fe708",
  null
  
)

export default component.exports